<app-dialog-container [class.loading]="isLoading">
  <ng-container title>
    <h2 class="text-center">Let's find your account</h2>
  </ng-container>
  <ng-container content>
    <form (submit)="submit()" [formGroup]="forgotForm">
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>E-mail Address</mat-label>
        <input matInput type="email" formControlName="email" />
        @if (email?.invalid && (email?.dirty || email?.touched)) {
        <mat-error>
          @if (email?.errors?.['required']) { Field is required } @if
          (email?.errors?.['pattern']) { Email is not valid }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      @if (requestError) {
      <p class="text-center text-sm color-invalid">{{ requestError }}</p>
      <div class="bs-xs-20"></div>
      }
      <div class="d-flex-align justify-content-between auth-align-md">
        <a (click)="back()" class="link-hover normal-weight">Back to Sign in</a>
        <button
          class="app-button btn-primary btn-lg"
          type="submit"
          [disabled]="!forgotForm.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container loader>
    <app-loader class="inner-loader" />
  </ng-container>
</app-dialog-container>
