import { Component, inject } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { passwordPattern } from '../../../core/validators/patterns';
import { matchFieldsValidator } from '../../../core/validators/match-fields-validator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { MatIconModule } from '@angular/material/icon';
import { LoaderComponent } from '../../../shared/components/general/loader/loader.component';
import { AuthService } from '../../../core/services/auth.service';
import { MessageService } from '../../../core/services/message.service';
import { finalize } from 'rxjs';
import {
  PASSWORD_HINT,
  ResponseMessages
} from '../../../core/constants/config';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  imports: [
    DialogContainerComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    LoaderComponent
  ]
})
export class ResetPasswordComponent {
  public dialogRef = inject(MatDialogRef<ResetPasswordComponent>);
  private dialog = inject(MatDialog);
  private authService = inject(AuthService);
  private messageService = inject(MessageService);
  resetForm = new FormGroup(
    {
      code: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(passwordPattern)
      ]),
      confirm_password: new FormControl(null, [Validators.required])
    },
    [matchFieldsValidator('password', 'confirm_password')]
  );
  isPasswordHidden = true;
  isConfirmPasswordHidden = true;
  isLoading = false;
  passwordHint = PASSWORD_HINT;
  get code() {
    return this.resetForm.get('code');
  }
  get password() {
    return this.resetForm.get('password');
  }
  get confirmPassword() {
    return this.resetForm.get('confirm_password');
  }

  reset() {
    if (this.resetForm.valid) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.authService
        .resetPassword(this.resetForm.value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.messageService.setSuccessMessage(
            ResponseMessages.USER_PASSWORD_UPDATE
          );
          this.back();
        });
    }
  }

  back() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
