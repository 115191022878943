<app-dialog-container [class.loading]="isLoading">
  <ng-container title><h2 class="text-center">Reset password</h2></ng-container>
  <ng-container content>
    <form (submit)="reset()" [formGroup]="resetForm">
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Code</mat-label>
        <input matInput type="text" formControlName="code" />
        @if (code?.invalid && (code?.dirty || code?.touched)) {
        <mat-error>
          @if (code?.errors?.['required']) { Field is required }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>New Password</mat-label>
        <input
          matInput
          [type]="isPasswordHidden ? 'password' : 'text'"
          formControlName="password"
        />
        @if (!isPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (password?.invalid && (password?.dirty || password?.touched)) {
        <mat-error>
          @if (password?.errors?.['required']) { Field is required }
        </mat-error>
        }
      </mat-form-field>
      <div class="bs-xs-5"></div>
      <div class="password-hint">
        <img src="assets/icons/password-information.svg" alt="" />
        <p class="text-sm lighter">
          {{ passwordHint }}
        </p>
      </div>
      <div class="bs-xs-20"></div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Repeat Password</mat-label>
        <input
          matInput
          [type]="isConfirmPasswordHidden ? 'password' : 'text'"
          formControlName="confirm_password"
        />
        @if (!isConfirmPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isConfirmPasswordHidden = !isConfirmPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isConfirmPasswordHidden = !isConfirmPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (confirmPassword?.invalid && (confirmPassword?.dirty ||
        confirmPassword?.touched)) {
        <mat-error>
          @if (confirmPassword?.errors?.['required']) { Field is required } @if
          (confirmPassword?.errors?.['matchField']) { The passwords don't match
          }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      <div class="d-flex-align justify-content-between auth-align-md">
        <a (click)="back()" class="link-hover normal-weight">Back to Sign in</a>
        <button
          type="submit"
          class="app-button btn-primary btn-lg"
          [disabled]="!resetForm.valid"
        >
          Send
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container loader>
    <app-loader class="inner-loader" />
  </ng-container>
</app-dialog-container>
