import { ValidatorFn, AbstractControl } from '@angular/forms';

export function matchFieldsValidator(
  controlName1: string,
  controlName2: string
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const control1 = control.get(controlName1)!;
    const control2 = control.get(controlName2)!;
    if (!control1.value && !control2.value) {
      control2.setErrors(null);
    } else if (control1.value !== control2.value) {
      control2.setErrors({ matchField: true });
    } else {
      control2.setErrors(null);
    }
    return null;
  };
}
