<app-dialog-container>
  <ng-container title><h2 class="text-center">Thank you</h2></ng-container>
  <ng-container content>
    <div class="text-center">
      <p class="text-sm lighter">
        You have successfully registered. Go to login to enter in your personal
        account
      </p>
      <div class="bs-xs-20"></div>
      <button class="app-button btn-primary btn-lg" mat-dialog-close>
        Close
      </button>
    </div>
  </ng-container>
</app-dialog-container>
