import { Component, inject } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { emailPattern } from '../../../core/validators/patterns';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../../../core/services/auth.service';
import { LoaderComponent } from '../../../shared/components/general/loader/loader.component';
import { finalize } from 'rxjs';
import { MessageService } from '../../../core/services/message.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { ResponseMessages } from '../../../core/constants/config';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  templateUrl: './forgot-password.component.html',
  imports: [
    DialogContainerComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    LoaderComponent
  ]
})
export class ForgotPasswordComponent {
  public dialogRef = inject(MatDialogRef<ForgotPasswordComponent>);
  private dialog = inject(MatDialog);
  private authService = inject(AuthService);
  private messageService = inject(MessageService);
  forgotForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(emailPattern)
    ])
  });
  requestError!: string;
  isLoading = false;
  get email() {
    return this.forgotForm.get('email');
  }

  submit() {
    if (this.forgotForm.valid) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.authService
        .verifyEmail(this.forgotForm.value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            this.messageService.setSuccessMessage(
              ResponseMessages.EMAIL_CODE_SEND
            );
            this.sendCodeDialog();
          },
          (error: any) => {
            this.requestError = error?.error?.message;
          }
        );
    }
  }

  sendCodeDialog() {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }

  back() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
