import { Component } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-thank-you',
  standalone: true,
  imports: [DialogContainerComponent, MatDialogModule],
  templateUrl: './thank-you.component.html'
})
export class ThankYouComponent {}
