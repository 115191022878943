import { Component, inject } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  emailPattern,
  passwordPattern
} from '../../../core/validators/patterns';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThankYouComponent } from '../thank-you/thank-you.component';
import { LoginComponent } from '../login/login.component';
import { MatIconModule } from '@angular/material/icon';
import { PASSWORD_HINT } from '../../../core/constants/config';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    DialogContainerComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule
  ],
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  public dialogRef = inject(MatDialogRef<LoginComponent>);
  private dialog = inject(MatDialog);
  registerForm = new FormGroup({
    first_name: new FormControl(null, [Validators.required]),
    last_name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(emailPattern)
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.pattern(passwordPattern)
    ])
  });
  requestError!: string;
  isPasswordHidden = true;
  passwordHint = PASSWORD_HINT;
  get firstName() {
    return this.registerForm.get('first_name');
  }
  get lastName() {
    return this.registerForm.get('last_name');
  }
  get email() {
    return this.registerForm.get('email');
  }
  get password() {
    return this.registerForm.get('password');
  }

  register() {
    const dialogRef = this.dialog.open(ThankYouComponent, {
      width: '690px'
    });

    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }

  login() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
