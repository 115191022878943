<app-dialog-container>
  <ng-container title><h2 class="text-center">Register</h2></ng-container>
  <ng-container content>
    <form (submit)="register()" [formGroup]="registerForm">
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <mat-form-field appearance="fill" class="app-form-field">
            <mat-label>First name</mat-label>
            <input matInput type="text" formControlName="first_name" />
            @if (firstName?.invalid && (firstName?.dirty || firstName?.touched))
            {
            <mat-error>
              @if (firstName?.errors?.['required']) { Field is required }
            </mat-error>
            }
          </mat-form-field>
          <div class="bs-xs-5"></div>
        </div>
        <div class="col-lg-6 col-xs-12">
          <mat-form-field appearance="fill" class="app-form-field">
            <mat-label>Last name</mat-label>
            <input matInput type="text" formControlName="last_name" />
            @if (lastName?.invalid && (lastName?.dirty || lastName?.touched)) {
            <mat-error>
              @if (lastName?.errors?.['required']) { Field is required }
            </mat-error>
            }
          </mat-form-field>
          <div class="bs-xs-5"></div>
        </div>
      </div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>E-mail Address</mat-label>
        <input matInput type="email" formControlName="email" />
        @if (email?.invalid && (email?.dirty || email?.touched)) {
        <mat-error>
          @if (email?.errors?.['required']) { Field is required } @if
          (email?.errors?.['pattern']) { Email is not valid }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="isPasswordHidden ? 'password' : 'text'"
          formControlName="password"
        />

        @if (!isPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (password?.invalid && (password?.dirty || password?.touched)) {
        <mat-error>
          @if (password?.errors?.['required']) { Field is required }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      <div class="password-hint">
        <img src="assets/icons/password-information.svg" alt="" />
        <p class="text-sm lighter">
          {{ passwordHint }}
        </p>
      </div>
      <div class="bs-xs-20"></div>
      @if (requestError) {
      <p class="text-center text-sm color-invalid">{{ requestError }}</p>
      <div class="bs-xs-20"></div>
      }
      <div class="d-flex-align justify-content-between auth-align-md">
        <a (click)="login()" class="link-hover normal-weight">
          Have an account? Sign in
        </a>
        <button
          type="submit"
          class="app-button btn-primary btn-lg"
          [disabled]="!registerForm.valid"
        >
          Create my account
        </button>
      </div>
      <div class="bs-xs-40"></div>
      <p class="lighter">
        By clicking “Create my account” you acknowledge the
        <a class="text-underline">Privacy Statement</a>
        and agree to be bound by the
        <a class="text-underline">Conditions of Sales & Services</a>
      </p>
    </form>
  </ng-container>
</app-dialog-container>
