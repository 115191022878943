<app-dialog-container [class.loading]="isLoading">
  <ng-container title><h2 class="text-center">Sign In</h2></ng-container>
  <ng-container content>
    <form (submit)="login()" [formGroup]="loginForm">
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>E-mail Address</mat-label>
        <input matInput type="email" formControlName="email" />
        @if (email?.invalid && (email?.dirty || email?.touched)) {
        <mat-error>
          @if (email?.errors?.['required']) { Field is required } @if
          (email?.errors?.['pattern']) { Email is not valid }
        </mat-error>
        }
      </mat-form-field>
      <div class="bs-xs-5"></div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="isPasswordHidden ? 'password' : 'text'"
          formControlName="password"
        />
        @if (!isPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (password?.invalid && (password?.dirty || password?.touched)) {
        <mat-error>
          @if (password?.errors?.['required']) { Field is required }
        </mat-error>
        }
      </mat-form-field>

      <div class="bs-xs-5"></div>
      @if (requestError) {
      <p class="text-center text-sm color-invalid">{{ requestError }}</p>
      <div class="bs-xs-20"></div>
      }
      <div class="d-flex-align justify-content-between auth-align-md">
        <a (click)="forgotPassword()" class="link-hover normal-weight">
          Forgot Your Password?
        </a>
        <button
          type="submit"
          class="app-button btn-primary btn-lg"
          [disabled]="!loginForm.valid"
        >
          Sign in
        </button>
      </div>
      <!-- <div class="bs-xs-20"></div>
      <div class="text-center">
        <p class="lighter">New to UrWayHealth?</p>
        <div class="bs-xs-5"></div>
        <a (click)="register()" class="link-hover normal-weight">
          Create an account
        </a>
      </div> -->
    </form>
  </ng-container>
  <ng-container loader>
    <app-loader class="inner-loader" />
  </ng-container>
</app-dialog-container>
