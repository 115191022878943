import { Component, inject } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { emailPattern } from '../../../core/validators/patterns';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../core/services/auth.service';
import { finalize } from 'rxjs';
import { LoaderComponent } from '../../../shared/components/general/loader/loader.component';
import { KlaviyoService } from 'src/app/core/services/klaviyo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [
    DialogContainerComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    LoaderComponent
  ]
})
export class LoginComponent {
  public dialogRef = inject(MatDialogRef<LoginComponent>);
  private dialog = inject(MatDialog);
  private authService = inject(AuthService);
  private klaviyoService = inject(KlaviyoService);
  loginForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(emailPattern)
    ]),
    password: new FormControl(null, [Validators.required])
  });
  requestError!: string;
  isPasswordHidden = true;
  isLoading = false;
  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  login() {
    if (this.loginForm.valid) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.authService
        .login(this.loginForm.value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (value: any) => {
            if (environment.production && this.email?.value) {
              this.klaviyoService.identifyUser(this.email.value);
            }
            this.dialogRef.close(true);
          },
          error => {
            this.requestError = error?.message;
          }
        );
    }
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }

  register() {
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '690px'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.close();
    });
  }
}
